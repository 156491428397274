.page-calendar {
  .breadcrumbs {
    display: none;
  }
  .top-bar {

    &-title {
      color: #000;
    }
    .logo {
      fill: #000;
    }

    .city {
      color: #000;
    }
    .menu-icon::after {
      background: #000;
      box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    }
  }

  .view-content {
      padding-bottom: 3rem;
  }

  .view-events h3 {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #484848;
  }

  .view-events .views-row-last .page-calendar__item {
    border-bottom:1px solid #D2D2D2;
  }

  &__item {
      border-top: 1px solid #D2D2D2;
      display: flex;
      text-align: center;

      &__flex-wrapper {
        display: flex;
        align-content: center;
        border-right: 1px solid #D2D2D2;

        &:last-child {
          border-right: none;
        }
      }

      &__wrapper {
        padding: 1rem 0;

        @include breakpoint(small only){
          padding: 0.3rem;
        }
      }

      &__date {
        align-self: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 700;
      }

      &__mobile-date
      &__time {
        align-self: center;

        .calendar-day {
          text-transform: uppercase;
          font-size: 1.1rem;
        }
      }

      &__title {
        align-self: center;
        font-size: 1.3rem;
        text-transform: uppercase;
        font-weight: 700;

        a {
          color: #000;
        }
      }

      &__path {
        align-self: center;

        a {
          color: #828282;
          font-size: 0.9rem;
        }
      }

      &__bookit {
        align-self: center;

        a {
          border: 2px solid black;
          padding: 0.4rem 0.75rem;
          margin-bottom: 0;
          color: #000;
        }

      }

    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n) {
      .page-days__item__title {
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
      }

      .page-days__item__djs {
        position: absolute;
        top: 3rem;
        right:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;

        @include breakpoint(small only){
          display: none;
        }
      }
    }

    &:nth-child(2n+1) {
      .page-days__item__title {
        position: absolute;
        bottom: 3rem;
        left: 3rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
      }

      .page-days__item__djs {
        position: absolute;
        top: 3rem;
        left:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;

        @include breakpoint(small only){
          display: none;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(medium down){
      margin-top: 0;
    }




  }

  &__description {
    @include breakpoint(medium down){
      padding: 0 2rem;
    }
  }

  &__image {
    display: block;
    margin: auto;
  }

  &__button-wrapper {
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  &__button {
    border: $button-border;
    color: $white;
    text-transform: uppercase;

    @include breakpoint(medium down){
      text-align: center;
    }
  }
}
