.view-home-teaser-djs {
  .view-content {
      @include flex-grid-row()
    }
}

.view-id-home_teaser_days {
  .pagination-centered {
    display: none;
    }
}

.block-block-7 h2.block-title, .view-home-teaser-today .event-style, .view-home-teaser-djs .card-section p {
  text-transform: uppercase;
  letter-spacing: 2px;
}


