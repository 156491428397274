.page-taxonomy-term {
  .day-hero-section {
    img {
      max-height: 60vh;
      width: 100%;
      object-fit: cover;
    }
    .hero-section-text {
      position: relative;
    }
    .headinfo {
      position: absolute;
      bottom: 40vh;
      left: 10vh;
      text-transform: uppercase;

      .dj-name {
        font-weight: 700;
      }
    }
  }

  .infobox {
      background-color: #181818;
      color: #fff;
      padding: 2rem 0;

      h1 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 3rem;
      }

      h3 {
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #1ED760;
      }
      iframe {
        border: none;
        width: 100%;
      }

      .share {
        padding-top: 1rem;

        a {
          color: #1ED760;
          display: flex;
          text-transform: uppercase;

          svg {
            fill: #fff;
            height: 2.5rem;
            width: 2.5rem;
            margin-left:2rem;
          }
        }

      }
  }
}
