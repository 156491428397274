.page-days {
  .breadcrumbs {
    display: none;
  }
  .top-bar {

    &-title {
      color: #000;
    }
    .logo {
      fill: #000;
    }

    .city {
      color: #000;
    }
    .menu-icon::after {
      background: #000;
      box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    }
  }

  .view-content {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  .view-grouping {
    position: relative;
    margin-top: -3rem;





    @include flex-grid-row (){}

    &-header {
      @include flex-grid-column(10);

      @include breakpoint(small only){
        @include flex-grid-column(12);
      }
    }

    &:nth-child(2n) {
      .view-grouping-content{
        position: absolute;
        top: 3rem;
        right:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        text-align: right;

        @include breakpoint(small only){
          display: none;
        }
      }
    }

    &:nth-child(2n+1) {

      .view-grouping-content {
        position: absolute;
        top: 3rem;
        left:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        text-align: left;

        @include breakpoint(small only){
          display: none;
        }

      }
    }


    &:nth-child(2n) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n) {
      .page-days__item__title {
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
      }

      .page-days__item__djs {
        position: absolute;
        top: 3rem;
        right:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;

        @include breakpoint(small only){
          display: none;
        }
      }
    }

    &:nth-child(2n+1) {
      .page-days__item__title {
        position: absolute;
        bottom: 3rem;
        left: 3rem;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 700;
      }

      .page-days__item__djs {
        position: absolute;
        top: 3rem;
        left:3rem;
        text-transform: uppercase;
        font-size: 1.2rem;

        @include breakpoint(small only){
          display: none;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint(medium down){
      margin-top: 0;
    }




  }

  &__description {
    @include breakpoint(medium down){
      padding: 0 2rem;
    }
  }

  &__image {
    display: block;
    margin: auto;
  }

  &__button-wrapper {
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  &__button {
    border: $button-border;
    color: $white;
    text-transform: uppercase;

    @include breakpoint(medium down){
      text-align: center;
    }
  }
}

.page-days__item__title.Saturday {
  color: #fff;
}

.page-days__item {
display: none;
}
