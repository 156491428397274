.footer {
  background: $cod-gray;
  color: $white;
  padding: 2rem 0 0;

  &__social {
    text-align: center;

    svg {
      fill: $white;
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
    }
  }

  &__logo {
    text-align: center;

    svg {
      fill: $white;
      width: 10rem;
      height: 4rem;
    }

    h4 {
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  &__location {
    text-align: center;

    h4 {
      font-size: 1rem;
    }
  }

  > .row {
    margin-bottom: 1rem;
  }

  &__menu {
    li:not(:last-child) {
        -webkit-box-shadow: 10px 0px 0px -9px white;
            -moz-box-shadow: 10px 0px 0px -9px white;
                box-shadow: 10px 0px 0px -9px white;
    }

    a {
      color: $white;
    }

    @include breakpoint(small only) {
      ul {
        flex-direction: column;
      }
    }
  }

  &__bottom {
    background-color: $white;
    color: $black;
    text-align: center;
    padding-top: 0.5rem;
    font-size: 0.8rem;

    @include breakpoint(small only) {
      font-size: 0.7rem;
    }
  }

  @include breakpoint(small only) {
    .columns {
      margin-bottom: 2rem;
    }
  }

}

