$hero-height: 80vh;

.hero-section {
  background: url('../img/_DSC4536-sm-GR.png') 50% no-repeat;
  background-size: cover;
  height: $hero-height;
  text-align: center;
  display: flex;
  margin-top: -6rem;
  align-items: center;
  justify-content: center;


  @include breakpoint(medium only) {
    height: 100vh;
  }

  @include breakpoint(small only) {
    height: 70vh;
  }

  & h1 {
    font-weight: 700;
    text-transform: uppercase;
  }

  .hero-section-text {
    color: $white;
    text-shadow: 1px 1px 2px $black;
  }


  #clockdiv{
    display: inline-block;
    font-weight: 100;
    text-align: center;
    font-size: 30px;

    @include breakpoint(medium only){
      font-size: 20px;
    }

    @include breakpoint(small only){
      font-size: 17px;
    }
  }

  #clockdiv > div{
    padding: 10px;
    display: inline-block;

    @include breakpoint(small only){
      padding: 0;
    }
  }

  #clockdiv div > span{
    padding: 15px;
    display: inline-block;
}
}
