.page-djs {
  .breadcrumbs {
    display: none;
  }
  .top-bar {

    &-title {
      color: #000;
    }
    .logo {
      fill: #000;
    }

    .city {
      color: #000;
    }
    .menu-icon::after {
      background: #000;
      box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    }
  }

  .view-content {
      padding-bottom: 3rem;
      padding-top: 3rem;

      @include breakpoint(small only) {
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
  }

  .view-display-id-block_1 {
    .column:nth-child(2n) .card {
      padding: 4rem;

      @include breakpoint(small only) {
        padding: 0;
      }
    }
  }

  .card {
    border: none;

    &-section {
      text-align: center;

      .dj__teaser__name {
        font-size: 1.4rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
      }

      p {
        font-size: 0.9rem;
        text-transform: uppercase;
      }
    }
  }
}




