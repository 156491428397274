$offcanvas-full-screen-bg: rgba(41, 47, 56, 0.95);;
$offcanvas-full-screen-side: top;
$offcanvas-full-screen-closebutton-size: 3rem;

.offcanvas-full-screen {
  @include off-canvas-base($background: $offcanvas-full-screen-bg, $transition: 0.3s ease-in-out);
  @include off-canvas-position($position: $offcanvas-full-screen-side, $size: 100%);
  // mix-blend-mode: multiply;
}
.offcanvas-full-screen.is-transition-overlap {
  z-index: 100;
}
.offcanvas-full-screen-inner {
  padding: 10rem;
  text-align: center;

  @include breakpoint(small only){
    padding: 5rem;
  }
}

.offcanvas-full-screen-menu {
  @include menu-base;
  @include menu-direction(vertical);

  a {
    color: $white;
    font-size: 1.6rem;
    text-transform: uppercase;

    &:hover {
      color: darken($white, 30%);
    }
  }

  ul {
    list-style-type: none;
  }
}

.offcanvas-full-screen-close {
  color: $white;
  font-size: $offcanvas-full-screen-closebutton-size;
  position: absolute;
  right: 1rem;
  top: 1rem;

  &:hover {
    color: darken($white, 30%);
  }
}

.top-bar {
  background-color: transparent;
  padding: 1rem;

  @include breakpoint(small only) {
    padding-bottom: 0;
    /* center logo at mobile */
    align-items: center;
    justify-content: center;
  }
  &__burger-button {
    width: 1.80rem;
    height: 1rem;
  }

  &-title {
    color: #fff;
    z-index: 99;
    width: 2rem;

    @include breakpoint(small only){
        flex-grow: 5;
        text-align: center;
        margin-right: -3rem;

      & .navbar-brand {
        margin-left: 0;
      }
    }
  }
  .logo {
    fill: #fff;
    width: 200px;
    height: 30px;
  }

  .city {
    color: #fff;
    text-transform: uppercase;
    z-index: 99;

    @include breakpoint(small only) {
      margin-left: -15rem;
      margin-top: 3rem;
    }
  }
}

.menu-icon {
    width: 30px;
    height: 26px;
    z-index: 99;
}


.page-node .top-bar, .page-taxonomy-term .top-bar{

&-title {
  color: #000;
}
.logo {
  fill: #000;
}

.city {
  color: #000;
}
.menu-icon::after {
  background: #000;
  box-shadow: 0 7px 0 #000, 0 14px 0 #000;
}
}


.page-node-18 .top-bar {

  &-title {
    color: #fff;
  }
  .logo {
    fill: #fff;
  }

  .city {
    color: #fff;
  }
  .menu-icon::after {
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
  }
}
