.events-menu {
  list-style-type: none;

  li {
    margin: 0 1rem;
  }
  a {
    color: #707070;
    text-transform: uppercase;
    font-size: 1.2rem;

    &.active {
      color: #171717;
    }
  }
}
