$gallery-image-offset-padding: 10px 0;
$gallery-title-color: #ECA077;

.gallery {
  background-color: $black;
  color: $white;

  &__title {
    color: $gallery-title-color;
    text-transform:uppercase;
    letter-spacing: 3px;

    @include breakpoint(medium down){
      padding: 1rem;
      text-align: center;
      font-size: 2rem;
    }
  }

  &__description {
    @include breakpoint(medium down){
      padding: 0 2rem;
    }
  }

  &__image {
    display: block;
    margin: auto;
  }

  &__button-wrapper {
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  &__button {
    border: $button-border;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:hover {
      color: #000;
      background: #fff;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      }

    @include breakpoint(medium down){
      text-align: center;
    }
  }
}

.page-node-18 {
  background-color: #161616;
  color: $white;

  .gallery-page {

    .gallery__text {
      color: #E0E0E0;
    }

    &__title {
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 3rem;
      color: #fff;
    }

    &__info {
      padding-bottom: 1rem;
      color: #B7B7B7;
      position: relative;
      margin-bottom: 3rem;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 25%;
        border-bottom: 1px solid #ECECEC;
      }
    }

    &__image {
      padding-bottom: 1rem;
    }

    &__title {
      text-transform: uppercase;
      color: #fff;
    }

    &__second-title {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: #fff;
    }

    &__features-header {
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
    }

    &__button-wrapper {
      text-align: center;
    }

    &__button {
      text-transform: uppercase;
      color: #000;
      font-size: 1.2rem;
      font-weight: 300;
      background-color: #BDBDBD;
      margin-bottom: 3rem;
      letter-spacing: 2px;
      padding: 1.5rem 3rem;
    }
  }

  .breadcrumbs {
    display: none;
  }


}
