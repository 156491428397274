$newsletter-bg: #E6EFF7;
$newsletter-button-width: 25vw; // % of wiewport width

.newsletter {
  background-color: $newsletter-bg;
  padding: 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  .input-group-field {
    border-radius: 0;
    position: relative;
    transform: translateX(0.5rem);
  }

  .input-group {
    margin-bottom: 0;
  }

  .newsletter-inner {
    text-align: center;
  }

  .button {
    border-radius: 0;
    text-transform: uppercase;
    background: transparent;
    letter-spacing: 2px;
    border: 1px solid #000;
    position: relative;
    transform: translateX(-0.5rem);
    min-width: $newsletter-button-width;

    &:hover {
      color: #fff;
      background-color: #000;
    }

    &:after {
      width: 0;
      height: 103%;
      top: 50%;
      left: 50%;
      background: #000;
      opacity: 0;
      -webkit-transform: translateX(-50%) translateY(-50%);
      -moz-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
    }

  }

  @include breakpoint(medium) {
    padding: 0 3rem;
  }

  @include breakpoint(large) {
    padding: 2rem 6rem;
  }
}
